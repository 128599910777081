import React from 'react'
import { MainLayout } from 'layouts'
import { NumberCardsRow } from './components'

const Dashboard = () => (
  <MainLayout>
    <NumberCardsRow />
  </MainLayout>
)

export default Dashboard