import React from 'react'
import { Layout } from 'antd'
import MainLayout from '../Main'
import useStyles from './styles'

const { Content } = Layout

const ContentLayout = ({ children, pageTitle, renderTopRightHeader }) => {
  const classes = useStyles()

  return (
    <MainLayout>
      <div className={classes.headerWrapper}>
        <div className={classes.pageTitle}>
          {pageTitle}
        </div>
        {renderTopRightHeader ? renderTopRightHeader() : null}
      </div>
      <Content className={classes.contentWrapper}>
        {children}
      </Content>
    </MainLayout>
  )
}

export default ContentLayout