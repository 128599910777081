import React, { useState } from 'react'
import { Divider, Form, Input, Button, Space } from 'antd'
import { ContentLayout } from 'layouts'
import { copyToClipboard } from 'utils/common'
import useStyles from './styles'

const CalculateYield = () => {
  const classes = useStyles()
  const [yieldRate, setYieldRate] = useState(undefined)

  const handleFormFinish = ({ dividendPerShare, pricePerShare }) => {
    const rawYieldRate = (dividendPerShare.includes(',') ? dividendPerShare.replace(',', '.') : dividendPerShare) / (pricePerShare.includes(',') ? pricePerShare.replace(',', '.') : pricePerShare) * 100
    setYieldRate(rawYieldRate.toFixed(2))
  }

  return (
    <ContentLayout pageTitle='Verim Hesapla'>
      <div className={classes.root}>
        <Form onFinish={handleFormFinish} layout="vertical" requiredMark={false}>
          <Form.Item
            label="Pay başı brüt temettü miktarı"
            name="dividendPerShare"
            rules={[{ required: true, message: 'Pay başı temettü miktarını giriniz' }]}
          >
            <Input addonBefore="Temettü" placeholder="Brüt miktar ₺" />
          </Form.Item>
          <Form.Item
            label="Birim hisse fiyatı"
            name="pricePerShare"
            rules={[{ required: true, message: 'Birim hisse fiyatını giriniz' }]}
          >
            <Input addonBefore="Hisse" placeholder="Brim hisse fiyatı ₺" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Hesapla
            </Button>
          </Form.Item>
        </Form>
        <Divider />
        {yieldRate && (
          <Space>
            <div>Temettü Verimi =</div>
            <Input addonBefore="%" value={yieldRate} />
            <Button htmlType="submit" onClick={() => copyToClipboard(yieldRate)}>
              Kopyala
            </Button>
          </Space>
        )}
      </div>
    </ContentLayout>
  )
}

export default CalculateYield
