import { createUseStyles } from 'react-jss'
import theme from 'config/theme'

const useStyles = createUseStyles({
  numberCard: {
    padding: '0px 20px',
    cursor: 'pointer',
    marginBottom: 15
  },
  itemIcon: {
    fontSize: 48,
    float: 'left',
    color: theme.color.primary
  },
  content: {
    width: '100%',
    marginTop: 12,
    paddingLeft: 78
  },
  title: {
    lineHeight: '16px',
    fontSize: 16,
    marginBottom: 8,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  number: {
    lineHeight: '32px',
    fontSize: 28,
    marginBottom: 0,
    fontWeight: 600,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: '#333'
  }
})

export default useStyles