import React from 'react'
import { Card } from 'antd'
import CountUp from 'react-countup'
import useStyles from './styles'

const NumberCard = ({
  renderIcon,
  isBordered = true,
  title,
  number,
  countUp
}) => {
  const classes = useStyles()

  return (
    <Card
      className={classes.numberCard}
      bordered={isBordered}
    >
      <div className={classes.itemIcon}>{renderIcon()}</div>
      <div className={classes.content}>
        <p className={classes.title}>{title || 'No Title'}</p>
        <p className={classes.number}>
          <CountUp
            start={0}
            end={number}
            duration={1.75}
            useEasing
            useGrouping
            separator=','
            {...(countUp || {})}
          />
        </p>
      </div>
    </Card>
  )
}

export default NumberCard