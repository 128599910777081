import React from 'react'
import { Select, Tag } from 'antd'
import { DIVIDEND_STATUS } from 'constants/common'

const { Option } = Select

const StatusSelect = ({ onChange, firstSelected = true, ...otherProps }) => (
  <Select
    showSearch
    placeholder='Durum seçiniz'
    defaultValue={firstSelected ? Object.keys(DIVIDEND_STATUS)[0] : undefined}
    onChange={onChange}
    filterOption={(input, option) => option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    {...otherProps}
  >
    {
      Object.entries(DIVIDEND_STATUS).map(
        status => (
          <Option key={status[0]} value={status[0]} title={status[1].title}>
            <Tag color={status[1].color}>{status[1].title}</Tag>
          </Option>
        )
      )
    }
  </Select>
)

export default StatusSelect