import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  error: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18
  }
})

export default useStyles