import { trim } from 'lodash'
import { NumberUtil } from 'utils'

export const getFormattedData = ({
  stockId, amountPerShare, grossAmountPerShare, yieldRate, date, paymentDate, status, active
}) => ({
  stockId,
  amountPerShare: parseFloat(amountPerShare),
  grossAmountPerShare: parseFloat(grossAmountPerShare),
  yieldRate: parseFloat(yieldRate),
  date,
  paymentDate,
  status: parseInt(status),
  active
})

export const validate = ({
  stockId, amountPerShare, grossAmountPerShare, yieldRate, date, paymentDate, status
}) => {
  if (!trim(stockId)) return false
  if (!trim(amountPerShare) || !NumberUtil.isFloat(amountPerShare)) return false
  if (!trim(grossAmountPerShare) || !NumberUtil.isFloat(grossAmountPerShare)) return false
  if (status === 200 && !trim(date)) return false
  if (status === 200 && !trim(paymentDate)) return false

  return true
}