import React from 'react'
import { render } from 'react-dom'
import moment from 'moment'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import trTR from 'antd/es/locale/tr_TR'
import 'moment/locale/tr'
import App from './app'
import './index.css'

moment.locale('tr')

render(
  <BrowserRouter>
    <ConfigProvider locale={trTR}>
      <App />
    </ConfigProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
