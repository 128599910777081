import React from 'react'
import moment from 'moment'
import { Avatar, Tag } from 'antd'

export const getTableColumns = ({ getColumnSearchProps }) => [
  {
    title: 'Avatar',
    dataIndex: 'name',
    key: 'name',
    width: 55,
    render: name => name ? <Avatar size={36}>{name.charAt(0)}</Avatar> : null
  },
  {
    title: 'İsim',
    dataIndex: 'name',
    key: 'name',
    width: 140,
    ...getColumnSearchProps('name'),
  }, 
  {
    title: 'Kullanıcı Adı',
    dataIndex: 'username',
    key: 'username',
    width: 140,
    ...getColumnSearchProps('username'),
  }, 
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 170,
    ...getColumnSearchProps('email')
  },
  {
    title: 'Kullanım',
    dataIndex: 'active',
    key: 'active',
    width: 200,
    render: active => !active ? <Tag color='red'>Devre Dışı</Tag> : <Tag color='blue'>Aktif</Tag>
  },
  {
    title: 'Kayıt Tarihi',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 200,
    render: createdAt => moment(Number(createdAt, 10)).format('LLL')
  }
]
