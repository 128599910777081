import React from 'react'
import { Modal } from 'antd'
import useStyles from './styles'

const CustomModal = ({
  title,
  isVisible,
  width,
  onCancel,
  children,
  ...otherProps
}) => {
  const classes = useStyles()

  return (
    <Modal
      className={classes.modal}
      title={title}
      visible={isVisible}
      width={width}
      maskClosable={false}
      footer={false}
      onCancel={onCancel}
      {...otherProps}
    >
      {children}
    </Modal>
  )
}

export default CustomModal