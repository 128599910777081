import React, { useState } from 'react'
import { Button } from 'antd'
import { ContentLayout } from 'layouts'
import { CustomModal } from 'components'
import { DividendsTable, AddDividendForm } from './components'

const Dividends = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)

  const handleModalCancel = () => {
    setIsAddModalVisible(false)
  }
  const handleAddNewClick = () => setIsAddModalVisible(true)

  const renderTopRightHeader = () => (
    <>
      <Button onClick={handleAddNewClick}>
        Yeni Ekle
      </Button>
      <CustomModal
        title='Yeni Temettü Ekle'
        isVisible={isAddModalVisible}
        width={700}
        onCancel={handleModalCancel}
        destroyOnClose={true}
      >
        <AddDividendForm onCancel={handleModalCancel} />
      </CustomModal>
    </>
  )

  return (
    <ContentLayout
      pageTitle='Temettüler'
      renderTopRightHeader={renderTopRightHeader}
    >
      <DividendsTable />
    </ContentLayout >
  )
}

export default Dividends