import React, { useContext } from 'react'
import { Avatar, Layout, Menu, Dropdown } from 'antd'
import { AuthContext } from 'context/auth'
import { SidebarMenu } from 'components'
import { ToastUtil } from 'utils'
import { removeItem } from 'utils/store'
import useStyles from './styles'

const { Header, Footer } = Layout

const MainLayout = ({ children }) => {
  const classes = useStyles()
  const { authDispatch, authState: { currentUser } } = useContext(AuthContext)

  const logOutUser = async () => {
    try {
      authDispatch({ type: 'LOGOUT_USER' })
      removeItem('currentUser')
      removeItem('token')
      window.location = '/'
    } catch (error) {
      ToastUtil.error()
    }
  }

  const menu = (
    <Menu>
      <Menu.Item key='0'>
        <span style={{ fontWeight: 600, color: '#0E698B' }}>{currentUser.name}</span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key='1' onClick={logOutUser}>
        Çıkış Yap
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout className={classes.mainLayout}>
      <SidebarMenu />
      <Layout>
        <Header className={classes.headerWrapper}>
          <Dropdown overlay={menu} trigger={['click']}>
            <a className='ant-dropdown-link' href='/#"' onClick={e => e.preventDefault()}>
              <Avatar size={38} style={{ color: '#0E698B', backgroundColor: 'rgba(20, 134, 162, 0.24)' }}>
                <span style={{ fontWeight: 600 }}>{currentUser ? currentUser.name.charAt(0) : null}</span>
              </Avatar>
            </a>
          </Dropdown>
        </Header>
        <div className={classes.contentWrapper}>
          {children}
        </div>
        <Footer className={classes.footerWrapper}>
          Panel for Temettü © {`${new Date().getFullYear()}`}
        </Footer>
      </Layout>
    </Layout>
  )
}

export default MainLayout