import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import { Card, Button, Form, Input, Tag, Radio } from 'antd'
import { get, isEmpty } from 'lodash'
import { ContentLayout } from 'layouts'
import { Spinner } from 'components'
import { ToastUtil } from 'utils'
import { copyToClipboard } from 'utils/common'
import useStyles from './styles'

const { TextArea } = Input

const TYPE = {
  NOTIFICATION: {
    KEY: "NOTIFICATION",
    TITLE: "Bildiri",
  },
  TODAY: {
    KEY: "TODAY",
    TITLE: "Bugün",
  },
}

const ParseUrl = () => {
  const classes = useStyles()

  const [type, setType] = useState(TYPE.NOTIFICATION.KEY)
  const [url, setUrl] = useState(undefined)
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [post, setPost] = useState(undefined)
  const [source, setSource] = useState(undefined)

  let API_BASE_URL = 'http://localhost:5000'

  if (process.env.NODE_ENV === 'production') {
    API_BASE_URL = 'https://temettu-cron.onrender.com'
  }

  const parseUrlRequest = ({ url, selectedType }) => {
    setUrl(url)
    setLoading(true)
    axios.get(`${API_BASE_URL}/parse`, {
      params: { url, isToday: selectedType === TYPE.TODAY.KEY || undefined }
    })
      .then(function (response) {
        setData(response.data)
        setLoading(false)
      })
      .catch(function (error) {
        ToastUtil.error(error)
        setLoading(false)
      })
  }

  const handleFormFinish = ({ url, selectedType }) => {
    setType(selectedType)
    parseUrlRequest({ url, selectedType })
  }
  const handlePostChange = (event) => {
    setPost(event.target.value)
  }
  const handleSourceChange = (event) => {
    setSource(event.target.value)
  }

  const getMultipleTextDate = (offeredDate, certainDate) => {
    console.log('offerDate', offeredDate)
    console.log('certainDate', certainDate)

    if (!offeredDate && !certainDate) {
      return 'Belirsiz'
    }
    if (certainDate) {
      return moment(certainDate).format('D MMMM')
    } else {
      return moment(offeredDate).format('D MMMM')
    }
  }

  useEffect(() => {
    setPost(undefined)
    setSource(undefined)

    if (data && data.dividends) {
      const { code, total } = data
      const { amountPerShare, yieldRate, offeredDate, certainDate, paymentDate } = data.dividends[0]

      if (type === TYPE.NOTIFICATION.KEY) {
        if (data.dividends.length > 1) {
          let offerTextMultiple = `🟡 #${code} toplamda pay başı net ₺${total.amountPerShare && Number(total.amountPerShare, 10).toFixed(2).replace('.', ',')} olmak üzere temettü dağıtma teklifinde bulundu. Yaklaşık verimi an itibariyle %${total.yieldRate && Number(total.yieldRate, 10).toFixed(1).replace('.', ',')}
          `
          let certainTextMultiple = `🟢 #${code} toplamda pay başı net ₺${total.amountPerShare && Number(total.amountPerShare, 10).toFixed(2).replace('.', ',')} olmak üzere temettü dağıtacağını açıkladı. Yaklaşık verimi an itibariyle %${total.yieldRate && Number(total.yieldRate, 10).toFixed(1).replace('.', ',')}
          `
          let isCertain = true
          data.dividends.forEach((dividend, index) => {
            if (!dividend.certainDate) {
              isCertain = false
            }
            offerTextMultiple += `
${index + 1}. taksit: ${getMultipleTextDate(dividend.offeredDate, dividend.certainDate)} - ₺${dividend.amountPerShare && Number(dividend.amountPerShare, 10).toFixed(2).replace('.', ',')} - %${dividend.yieldRate && Number(dividend.yieldRate, 10).toFixed(1).replace('.', ',')}`
            certainTextMultiple += `
${index + 1}. taksit: ${getMultipleTextDate(dividend.offeredDate, dividend.certainDate)} - ₺${dividend.amountPerShare && Number(dividend.amountPerShare, 10).toFixed(2).replace('.', ',')} - %${dividend.yieldRate && Number(dividend.yieldRate, 10).toFixed(1).replace('.', ',')}`
          })
          setPost(isCertain ? certainTextMultiple : offerTextMultiple)
        } else {
          const offerText = `🟡 #${code} ${offeredDate ? `${moment(offeredDate).format('D MMMM')} tarihinde ` : ''}pay başı net ₺${amountPerShare && Number(amountPerShare, 10).toFixed(2).replace('.', ',')} olmak üzere temettü dağıtma teklifinde bulundu. Yaklaşık verimi an itibariyle %${yieldRate && Number(yieldRate, 10).toFixed(1).replace('.', ',')}
      
Ödeme tarihi ${paymentDate ? moment(paymentDate).format('D MMMM') : 'henüz belirsiz'}.

Fiyat ve Verim hakkında ne düşünüyorsunuz?

#temettü #borsa #yatırım #hisse`

          const certainText = `🟢 #${code} ${moment(certainDate).format('D MMMM')} tarihinde pay başı net ₺${amountPerShare && Number(amountPerShare, 10).toFixed(2).replace('.', ',')} temettü dağıtacağını açıkladı. Yaklaşık verimi an itibariyle %${yieldRate && Number(yieldRate, 10).toFixed(1).replace('.', ',')}
              
Ödeme tarihi ${moment(paymentDate).format('D MMMM')}.

Fiyat ve Verim hakkında ne düşünüyorsunuz?

#temettü #borsa #yatırım #hisse
`

          setPost(!data.dividends[0].certainDate ? offerText : certainText)
        }
      }

      if (type === TYPE.TODAY.KEY) {
        const text = `Bugün #${code} yatırımcılarına pay başı net ₺${amountPerShare && Number(amountPerShare, 10).toFixed(2).replace('.', ',')} temettü dağıtıyor. Yaklaşık verimi son fiyatıyla %{placeholder}
      
Ödeme tarihi ${moment(paymentDate).format('D MMMM')}.

Fiyat ve Verim hakkında ne düşünüyorsunuz?

#temettü #borsa #yatırım #hisse`

        setPost(text)
      }

      const sourceText = `🔗 @temettuapp
${url}`
      setSource(sourceText)
    }
  }, [data, url, type])

  return (
    <ContentLayout pageTitle='Gönderi Oluştur'>
      <div className={classes.root}>
        <div className={classes.form}>
          <Form
            layout="vertical"
            onFinish={handleFormFinish}
            requiredMark={false}
            initialValues={{ selectedType: TYPE.NOTIFICATION.KEY }}
          >
            <Form.Item label="Gönderi Tipi" name="selectedType">
              <Radio.Group>
                <Radio value={TYPE.NOTIFICATION.KEY}>{TYPE.NOTIFICATION.TITLE}</Radio>
                <Radio value={TYPE.TODAY.KEY}>{TYPE.TODAY.TITLE}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="URL"
              name="url"
              rules={[{ required: true, message: 'Bildirim linkini giriniz' }]}
            >
              <Input placeholder="https://www.kap.org.tr/tr/Bildirim/907163" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Oluştur
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className={classes.content}>
          <Card>
            {loading ? <Spinner /> : (
              <>
                <div><strong>Bildiri tipi:</strong> <Tag>{get(data, 'type.title')}</Tag></div>
                <div><strong>Hisse kodu:</strong> {get(data, 'code')}</div>
                <div><strong>Hisse ismi:</strong> {get(data, 'name')}</div>
                <div><strong>Son fiyat:</strong> {get(data, 'latestPrice')}</div>
                {data.total && (
                  <>
                    <div><strong>Toplam pay başı net:</strong> {get(data, 'total.amountPerShare')}</div>
                    <div><strong>Toplam verim:</strong> {get(data, 'total.yieldRate')}</div>
                  </>
                )}
                {get(data, 'dividends') && (
                  data.dividends.map((dividend, index) => (
                    <div key={index} style={{ margin: '5px 0', borderTop: '1px solid #f4f4f4', paddingTop: 10 }}>
                      <div style={{ marginLeft: 10 }}>
                        <div><strong>Pay başı (brüt):</strong> {dividend.grossAmountPerShare}</div>
                        <div><strong>Pay başı (net):</strong> {dividend.amountPerShare}</div>
                        <div><strong>Verim:</strong> {dividend.yieldRate}</div>
                        <div><strong>Tarih:</strong> {dividend.certainDate ? dividend.certainDate : dividend.offeredDate}</div>
                        <div><strong>Ödeme tarihi:</strong> {dividend.paymentDate}</div>
                        <div><strong>Durum:</strong> {dividend.certainDate ? <Tag color="green">Karar Verildi</Tag> : <Tag color="orange">Karar Aşamasında</Tag>}</div>
                      </div>
                    </div>
                  )
                  ))}
                {!isEmpty(data) && (
                  <div className={classes.post}>
                    {/* {type === TYPE.TODAY.KEY && (
                      <div style={{ marginBottom: 20 }}>
                        <div style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 10 }}>Görsel</div>
                        <div style={{ backgroundColor: '#f4f4f4', width: '100%', height: 300, borderRadius: 10, marginBottom: 20 }} />
                        <Button htmlType="submit" onClick={() => alert('download')} style={{ marginBottom: 10 }}>
                          İndir
                        </Button>
                      </div>
                    )} */}
                    <div style={{ fontWeight: 'bold', marginBottom: 5 }}>Bildiri</div>
                    <TextArea
                      rows={6}
                      showCount
                      maxLength={280}
                      value={post}
                      onChange={handlePostChange}
                    />
                    <Button htmlType="submit" onClick={() => copyToClipboard(post)}>
                      Kopyala
                    </Button>
                    <div style={{ fontWeight: 'bold', marginTop: 30, marginBottom: 5 }}>Kaynak</div>
                    <TextArea
                      rows={4}
                      showCount
                      maxLength={280}
                      value={source}
                      onChange={handleSourceChange}
                    />
                    <Button htmlType="submit" onClick={() => copyToClipboard(source)}>
                      Kopyala
                    </Button>
                  </div>
                )}
              </>
            )}
          </Card>
        </div>
      </div>
    </ContentLayout>
  )
}

export default ParseUrl
