import React, { useState } from 'react'
import { Button, Form, Input, Switch } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { STOCKS } from 'api/stock/query'
import { UPDATE_STOCK } from 'api/stock/mutation'
import { CustomButton } from 'components'
import { ToastUtil } from 'utils'
import { getFormattedData, validate } from '../utils'
import useStyles from './styles'

const UpdateStockForm = ({ selectedItem, onCancel }) => {
  const classes = useStyles()
  const [stockToUpdate, setStockToUpdate] = useState(selectedItem)
  const [updatePending, setUpdatePending] = useState(false)
  const [updateStock] = useMutation(UPDATE_STOCK)

  const updateStockRequest = async data => {
    setUpdatePending(true)
    const response = await updateStock(
      {
        variables: { id: selectedItem.id, data },
        refetchQueries: [
          { query: STOCKS, variables: { orderBy: 'id_DESC' } }
        ]
      }
    )
    console.log(response)
    setUpdatePending(false)
    return response
  }

  const handleSubmit = async () => {
    const data = getFormattedData(stockToUpdate)

    if (!validate(data)) {
      ToastUtil.error('Geçersiz veri')
      return
    }

    try {
      await updateStockRequest(data)
      ToastUtil.success('Başarıyla güncellendi')

      onCancel()
    } catch (error) {
      setUpdatePending(false)
      ToastUtil.error(error.message)
    }
  }
  const { name, code, active } = stockToUpdate

  return (
    <Form
      layout='vertical'
      onFinish={handleSubmit}
    >
      <Form.Item label='Kod'>
        <div className={classes.flexRow}>
          <Input
            className={classes.rightSpaced}
            placeholder='Kod'
            value={code ? code.toUpperCase() : null}
            onChange={event => setStockToUpdate({ ...stockToUpdate, code: event.target.value })}
          />
        </div>
      </Form.Item>
      <Form.Item label='İsim'>
        <Input
          placeholder='İsim'
          value={name}
          onChange={event => setStockToUpdate({ ...stockToUpdate, name: event.target.value })}
        />
      </Form.Item>
      <Form.Item label='Kullanım'>
        <Switch
          checkedChildren='Aktif'
          unCheckedChildren='Devre Dışı'
          defaultChecked={active ? true : false}
          onChange={checked => setStockToUpdate({ ...stockToUpdate, active: checked })}
        />
      </Form.Item>
      <div className={classes.btnContainer}>
        <Button
          className={classes.cancelBtn}
          onClick={onCancel}
        >
          İptal
        </Button>
        <CustomButton
          type='primary'
          htmlType='submit'
          loading={updatePending}
        >
          Güncelle
        </CustomButton>
      </div>
    </Form>
  )
}

export default UpdateStockForm