import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { STOCKS } from 'api/stock/query'

const { Option } = Select

const StockSelect = ({ onChange, ...otherProps }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchingItem, setSearchingItem] = useState('')

  const { loading, error, data } = useQuery(
    STOCKS,
    { variables: { orderBy: 'code_ASC', limit: 20, query: searchingItem } }
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!searchTerm) {
        setSearchingItem('')
        return
      }
      setSearchingItem(searchTerm)
    }, 600)

    return () => clearTimeout(timeout)
  }, [searchTerm])

  if (loading) return <Select placeholder='Yükleniyor...' />
  if (error) return <Select placeholder='Hata :(' />

  const { stocks } = data

  return (
    <Select
      showSearch
      placeholder='Hisse seçiniz'
      onChange={onChange}
      filterOption={(input, option) => option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.code.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      onSearch={setSearchTerm}
      loading={loading}
      {...otherProps}
    >
      {
        stocks.map(
          stock => (
            <Option key={stock.id} value={stock.id} name={stock.name} code={stock.code}>
              {stock.code} - {stock.name}
            </Option>
          )
        )
      }
    </Select>
  )
}

export default StockSelect