import { gql } from 'apollo-boost'

export const CREATE_DIVIDEND = gql`
  mutation CreateDividend($data: CreateDividendInput!) {
    createDividend(data: $data) {
      id
    }
  }
`
export const DELETE_DIVIDEND = gql`
  mutation DeleteDividend($id: Int!) {
    deleteDividend(id: $id)
  }
`
export const UPDATE_DIVIDEND = gql`
  mutation UpdateDividend(
    $id: Int!
    $data: UpdateDividendInput!
  ) {
    updateDividend(id: $id data: $data) {
      id
    }
  }
`