import React, { useContext, useReducer } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { ApolloProvider } from '@apollo/react-hooks'
import { AuthContext } from './context/auth'
import { authReducer } from './reducers/auth'
import { getApolloClient } from './utils/apollo'
import { removeItem } from './utils/store'
import { ToastUtil } from './utils'
import { getItem } from './utils/store'
import {
  CalculateYield,
  Dashboard,
  Stocks,
  Dividends,
  Login,
  Users,
  ParseUrl,
  Tools,
  Signup,
  UpdateYield,
} from './pages'
import './app.css'

ToastUtil.init()

const App = () => {
  const initialAuthState = useContext(AuthContext)
  const [authState, authDispatch] = useReducer(authReducer, initialAuthState)

  const logOutUser = async () => {
    try {
      authDispatch({ type: 'LOGOUT_USER' })
      removeItem('currentUser')
      removeItem('token')
    } catch (error) {
      ToastUtil.error()
    }
  }

  const currentUser = getItem('currentUser')

  const LandingRoute = () => (
    <Route
      render={() => !currentUser
        ? <Redirect to={{ pathname: '/login' }} />
        : <Redirect to={{ pathname: '/dashboard' }} />}
    />
  )

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => !currentUser
        ? <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        : <Component {...props} />}
    />
  )

  const LoginRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => !currentUser
        ? <Login {...props} />
        : <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />}
    />
  )

  const SignupRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => !currentUser
        ? <Signup {...props} />
        : <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />}
    />
  )

  return (
    <ApolloProvider client={getApolloClient({ onLogout: logOutUser })}>
      <AuthContext.Provider value={{ authState, authDispatch }}>
        <Switch>
          <LandingRoute exact path='/' />
          <Route path='/login' render={props => <LoginRoute {...props} />} />
          <Route path='/signup' render={props => <SignupRoute {...props} />} />
          <PrivateRoute path='/dashboard' component={Dashboard} />
          <PrivateRoute path='/stocks' component={Stocks} />
          <PrivateRoute path='/dividends' component={Dividends} />
          <PrivateRoute path='/tools' component={Tools} />
          <PrivateRoute path='/calculateYieldRate' component={CalculateYield} />
          <PrivateRoute path='/updateYield' component={UpdateYield} />
          <PrivateRoute path='/parseUrl' component={ParseUrl} />
          <PrivateRoute path='/users' component={Users} />
          <Redirect to='/' />
        </Switch>
      </AuthContext.Provider>
    </ApolloProvider>
  )
}

export default App
