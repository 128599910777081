import React, { useState, useRef, useEffect } from 'react'
import Highlighter from 'react-highlight-words'
import { get } from 'lodash'
import { Input, Button, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { STOCKS } from 'api/stock/query'
import { DELETE_STOCK } from 'api/stock/mutation'
import {
  DataTable,
  Spinner,
  Error,
  TableRowActions,
  CustomModal
} from 'components'
import { ToastUtil } from 'utils'
import { getTableColumns } from './config'
import UpdateStockForm from '../Form/Update'

const LIMIT = 10
const INITIAL_OFFSET = 0

const StocksTable = () => {
  const inputRef = useRef(null)

  const [limit] = useState(LIMIT)
  const [offset, setOffset] = useState(INITIAL_OFFSET)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [deleteItem] = useMutation(DELETE_STOCK)
  const { loading, error, data } = useQuery(
    STOCKS,
    { variables: { orderBy: 'id_DESC', limit, offset, query: searchText || undefined } }
  )

  useEffect(() => {
    setOffset(INITIAL_OFFSET)
  }, [searchText])

  const handleDelete = async ({ id }) => {
    try {
      await deleteItem({
        variables: { id },
        refetchQueries: [
          { query: STOCKS, variables: { orderBy: 'id_DESC', limit, offset, query: searchText || undefined } }
        ]
      })
      ToastUtil.success('Başarıyla silindi')
    } catch (error) {
      ToastUtil.error(error.message)
    }
  }

  const handleEdit = item => {
    setSelectedItem(item)
    setIsEditModalVisible(true)
  }

  const renderTableRowActions = item => (
    <TableRowActions
      item={item}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
    />
  )

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputRef}
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => get(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => inputRef.current.select());
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : text
  })

  if (loading) return <Spinner />
  if (error) return <Error />

  const { stocks } = data

  return (
    <>
      <DataTable
        data={stocks}
        columns={getTableColumns({ renderTableRowActions, getColumnSearchProps })}
        pagination={false}
      />
      <div style={{ padding: '15px 0', display: 'flex', justifyContent: 'center' }}>
        <Space>
          <Button onClick={() => setOffset(offset - limit)} disabled={offset - limit < 0}>Geri</Button>
          <Button onClick={() => setOffset(offset + limit)}>İleri</Button>
        </Space>
      </div>
      <CustomModal
        title='Hisseyi Güncelle'
        isVisible={isEditModalVisible}
        width={700}
        onCancel={() => setIsEditModalVisible(false)}
        destroyOnClose={true}
      >
        <UpdateStockForm
          selectedItem={selectedItem}
          onCancel={() => setIsEditModalVisible(false)}
        />
      </CustomModal>
    </>
  )
}

export default StocksTable