import { gql } from 'apollo-boost'

export const DIVIDENDS = gql`
  query Dividends($limit: Int, $offset: Int, $orderBy: String, $query: String, $active: Boolean, $year: Int) {
    dividends(limit: $limit, offset: $offset, orderBy: $orderBy, query: $query, active: $active, year: $year) {
      id
      yieldRate
      amountPerShare
      grossAmountPerShare
      status
      date
      paymentDate
      active
      stock {
        id
        code
        name
      }
      createdAt
      updatedAt
    }
  }
`