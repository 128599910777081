import { createUseStyles } from 'react-jss'
import theme from 'config/theme'

const useStyles = createUseStyles({
  button: ({ fullWidth, boldText }) => ({
    width: fullWidth ? '100%' : null,
    color: 'white',
    backgroundColor: theme.color.primary,
    border: 'None',
    '&:hover': {
      color: 'white',
      backgroundColor: theme.color.hover,
    },
    '&:focus': {
      color: 'white',
      backgroundColor: theme.color.hover,
    },
    fontWeight: boldText ? 'bold' : null
  })
})

export default useStyles