import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { get, filter } from 'lodash'
import { Menu, Layout } from 'antd'
import {
  DashboardOutlined,
  LineChartOutlined,
  PieChartOutlined,
  ScissorOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { sidebarMenuItems } from 'config/sidebar'
import useStyles from './styles'

const { Sider } = Layout
const { SubMenu } = Menu

const renderIcon = type => {
  switch (type) {
    case 'dashboard':
      return <DashboardOutlined />
    case 'stock':
      return <LineChartOutlined />
    case 'dividend':
      return <PieChartOutlined />
    case 'tools':
      return <ScissorOutlined />
    case 'users':
      return <UserOutlined />
    default:
      return null
  }
}

const SidebarMenu = () => {
  const classes = useStyles()
  const history = useHistory()
  const { pathname } = useLocation()

  const getOpenSubItemKey = () => {
    return get(filter(sidebarMenuItems, { subItems: [{ key: pathname.replace('/', '') }] })[0], 'key')
  }

  return (
    <Sider
      trigger={null}
      collapsible={window.screen.width < 600 ? true : false}
      collapsed={window.screen.width < 600 ? true : false}
    >
      <div className={classes.logoWrapper} onClick={() => history.push('/dashboard')}>
        {window.screen.width < 600 ? 'te.' : 'temettü.'}
      </div>
      <Menu
        theme='dark'
        mode='inline'
        selectedKeys={pathname && [pathname.replace('/', '')]}
        defaultOpenKeys={[getOpenSubItemKey()]}
      >
        {
          sidebarMenuItems.map(menuItem => !menuItem.subItems ? (
            <Menu.Item
              key={menuItem.key}
              onClick={() => history.push(menuItem.path)}
            >
              <>
                {renderIcon(menuItem.icon)}
                <span>{menuItem.title}</span>
              </>
            </Menu.Item>
          ) : (
              <SubMenu key={menuItem.key} icon={renderIcon(menuItem.icon)} title={menuItem.title}>
                {menuItem.subItems.map(
                  (subItem) => (
                    <Menu.Item
                      key={subItem.key}
                      onClick={() => history.push(subItem.path)}
                    >
                      <>
                        <span>{subItem.title}</span>
                      </>
                    </Menu.Item>
                  )
                )}
              </SubMenu>
            )
          )}
      </Menu>
    </Sider>
  )
}

export default withRouter(SidebarMenu)