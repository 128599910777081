export const sidebarMenuItems = [
  {
    key: 'dashboard',
    icon: 'dashboard', // antdesign icon key
    title: 'Anasayfa',
    path: '/dashboard',
  },
  {
    key: 'users',
    icon: 'users',
    title: 'Kullanıcılar',
    path: '/users',
  },
  {
    key: 'stocks',
    icon: 'stock',
    title: 'Hisseler',
    path: '/stocks',
  },
  {
    key: 'dividends',
    icon: 'dividend',
    title: 'Temettüler',
    path: '/dividends',
  },
  {
    key: 'tools',
    icon: 'tools',
    title: 'Araçlar',
    path: '/tools',
    subItems: [
      {
        key: 'parseUrl',
        title: 'Gönderi Oluştur',
        path: '/parseUrl',
      },
      {
        key: 'calculateYieldRate',
        title: 'Verim Hesapla',
        path: '/calculateYieldRate',
      },
      {
        key: 'updateYield',
        title: 'Verim Güncelle',
        path: '/updateYield',
      },
    ]
  }
]
