import React from 'react'
import { trim } from 'lodash'
import { Form, Input } from 'antd'
import { MailOutlined, LockOutlined, UserOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { CustomButton } from 'components'
import { ADMIN_SIGN_UP } from 'api/auth/mutation'
import { ToastUtil } from 'utils'
import bgUrl from 'assets/images/bg.png'
import useStyles from './styles'

const Signup = () => {
  const classes = useStyles({ bgUrl })
  const [form] = Form.useForm()
  const [adminSignUp, { loading }] = useMutation(ADMIN_SIGN_UP)

  const handleLogIn = async ({ name, email, password }) => {
    if (trim(name) && trim(email) && trim(password)) {
      try {
        const { data: { adminSignUp: { admin } } } = await adminSignUp(
          {
            variables: {
              name: trim(name),
              email: trim(email),
              password: trim(password)
            }
          }
        )
        if (admin) {
          form.resetFields()
          ToastUtil.success('Kullanıcı oluşturuldu')
        }
      } catch (error) {
        ToastUtil.error(error.message)
      }
    }
  }

  return (
    <div className={classes.signupContainer}>
      <div className={classes.signupForm}>
        <Form
          form={form}
          name='normal_signup'
          className='signup-form'
          onFinish={({ name, email, password }) => handleLogIn({ name, email, password })}
        >
          <div className={classes.logoWrapper}>
            temettü.
          </div>
          <Form.Item
            name='name'
            rules={[
              {
                required: true,
                message: 'İsminizi girin',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder='İsim'
              size='large'
            />
          </Form.Item>
          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                message: 'Email adresinizi girin',
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className='site-form-item-icon' />}
              placeholder='Email'
              size='large'
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: 'Parolanızı girin',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className='site-form-item-icon' />}
              type='password'
              placeholder='Parola'
              size='large'
            />
          </Form.Item>
          <Form.Item>
            <CustomButton
              type='primary'
              htmlType='submit'
              size='large'
              fullWidth
              boldText
              loading={loading}
            >
              Kayıt Ol
          </CustomButton>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Signup
