import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Button, Form, Input, Switch } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { DIVIDENDS } from 'api/dividend/query'
import { UPDATE_DIVIDEND } from 'api/dividend/mutation'
import { CustomButton, StatusSelect, DatePicker } from 'components'
import { ToastUtil } from 'utils'
import { getFormattedData, validate } from '../utils'
import useStyles from './styles'

const UpdateDividendForm = ({ selectedItem, onCancel }) => {
  const classes = useStyles()
  const [dividendToUpdate, setDividendToUpdate] = useState(selectedItem)
  const [updatePending, setUpdatePending] = useState(false)
  const [updateDividend] = useMutation(UPDATE_DIVIDEND)

  useEffect(() => {
    const { stock: { id } } = selectedItem
    setDividendToUpdate({ ...dividendToUpdate, stockId: id })
    // eslint-disable-next-line
  }, [])

  const updateDividendRequest = async data => {
    setUpdatePending(true)
    const response = await updateDividend(
      {
        variables: { id: selectedItem.id, data },
        refetchQueries: [
          { query: DIVIDENDS, variables: { orderBy: 'id_DESC' } }
        ]
      }
    )
    setUpdatePending(false)
    return response
  }

  const handleSubmit = async () => {
    const data = getFormattedData(dividendToUpdate)

    if (!validate(data)) {
      ToastUtil.error('Geçersiz veri')
      return
    }

    try {
      await updateDividendRequest(data)
      ToastUtil.success('Başarıyla güncelledi')

      onCancel()
    } catch (error) {
      setUpdatePending(false)
      ToastUtil.error(error.message)
    }
  }

  const { amountPerShare, grossAmountPerShare, yieldRate, date, paymentDate, status, active, stock } = dividendToUpdate

  return (
    <Form
      layout='vertical'
      onFinish={handleSubmit}
    >
      <Form.Item label='Hisse'>
        <Input disabled value={stock && `${stock.code} - ${stock.name}`} />
      </Form.Item>
      <Form.Item label='Miktar'>
        <div className={classes.flexRow}>
          <Input
            className={classes.rightSpaced}
            addonBefore="Brüt ₺"
            placeholder='Brüt Miktar'
            value={grossAmountPerShare}
            onChange={event => setDividendToUpdate({ ...dividendToUpdate, grossAmountPerShare: event.target.value })}
          />
          <Input
            addonBefore="Net ₺"
            placeholder='Net Miktar'
            value={amountPerShare}
            onChange={event => setDividendToUpdate({ ...dividendToUpdate, amountPerShare: event.target.value })}
          />
        </div>
      </Form.Item>
      <Form.Item label='Verim'>
        <div className={classes.flexRow}>
          <Input
            placeholder='Verim'
            addonBefore="Verim %"
            value={yieldRate}
            onChange={event => setDividendToUpdate({ ...dividendToUpdate, yieldRate: event.target.value })}
          />
        </div>
      </Form.Item>
      <Form.Item label='Tarih - Ödeme Tarihi'>
        <div className={classes.flexRow}>
          <DatePicker
            className={classes.rightSpaced}
            value={moment(date)}
            onChange={(date, dateString) => setDividendToUpdate({ ...dividendToUpdate, date: dateString })}
          />
          <DatePicker
            className={classes.datePicker}
            value={moment(paymentDate)}
            onChange={(date, dateString) => setDividendToUpdate({ ...dividendToUpdate, paymentDate: dateString })}
          />
        </div>
      </Form.Item>
      <Form.Item label='Durum - Kullanım '>
        <div className={classes.flexRow}>
          <StatusSelect
            className={classes.rightSpaced}
            firstSelected={false}
            value={`${status}`}
            onChange={value => setDividendToUpdate({ ...dividendToUpdate, status: value })}
          />
          <div className={classes.switch}>
            <Switch
              checkedChildren='Aktif'
              unCheckedChildren='Devre Dışı'
              defaultChecked={active ? true : false}
              onChange={checked => setDividendToUpdate({ ...dividendToUpdate, active: checked })}
            />
          </div>
        </div>
      </Form.Item>
      <div className={classes.btnContainer}>
        <Button
          className={classes.cancelBtn}
          onClick={onCancel}
        >
          İptal
        </Button>
        <CustomButton
          type='primary'
          htmlType='submit'
          loading={updatePending}
        >
          Güncelle
        </CustomButton>
      </div>
    </Form>
  )
}

export default UpdateDividendForm