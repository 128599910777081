import { gql } from 'apollo-boost'

export const STATS = gql`
  query Stats {
    stats {
      stockCount
      dividendCount
      userCount
      daysPassed
    }
  }
`