import React from 'react'
import { Dropdown, Menu, Modal } from 'antd'
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

const { confirm } = Modal

const TableRowActions = ({
  item,
  handleDelete,
  handleEdit
}) => {
  const showDeleteConfirm = () => {
    confirm({
      title: 'Kaydı sil?',
      icon: <ExclamationCircleOutlined />,
      content: 'Kayıtlar kalıcı olarak silinmektedir.',
      okText: 'Sil',
      okType: 'danger',
      onOk: () => handleDelete(item),
      onCancel() { },
    })
  }

  const actionMenu = (
    <Menu>
      <Menu.Item onClick={() => handleEdit(item)}>
        Düzenle
      </Menu.Item>
      <Menu.Item onClick={showDeleteConfirm}>
        Sil
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown
      overlay={actionMenu}
      placement='bottomCenter'
      trigger={['click']}
    >
      <DownOutlined />
    </Dropdown>
  )
}

export default TableRowActions