import React, { useContext } from 'react'
import { trim } from 'lodash'
import { Form, Input } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { CustomButton } from 'components'
import { ADMIN_SIGN_IN } from 'api/auth/mutation'
import { AuthContext } from 'context/auth'
import { ToastUtil } from 'utils'
import { storeItem } from 'utils/store'
import bgUrl from 'assets/images/bg.png'
import useStyles from './styles'

const Login = () => {
  const classes = useStyles({ bgUrl })
  const { authDispatch } = useContext(AuthContext)
  const [adminSignIn, { loading }] = useMutation(ADMIN_SIGN_IN)

  const handleLogIn = async ({ email, password }) => {
    if (trim(email) && trim(password)) {
      try {
        const { data: { adminSignIn: { admin, token } } } = await adminSignIn(
          {
            variables: {
              email: trim(email),
              password: trim(password)
            }
          }
        )

        if (token) {
          authDispatch({ type: 'LOGIN_USER', payload: admin })
          storeItem('currentUser', admin)
          storeItem('token', token)
          window.location = '/'
        }
      } catch (error) {
        ToastUtil.error(error.message)
      }
    }
  }

  return (
    <div className={classes.loginContainer}>
      <div className={classes.loginForm}>
        <Form
          name='normal_login'
          className='login-form'
          onFinish={({ email, password }) => handleLogIn({ email, password })}
        >
          <div className={classes.logoWrapper}>
            temettü.
          </div>
          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                message: 'Email adresinizi girin',
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className='site-form-item-icon' />}
              placeholder='Email'
              size='large'
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: 'Parolanızı girin',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className='site-form-item-icon' />}
              type='password'
              placeholder='Parola'
              size='large'
            />
          </Form.Item>
          <Form.Item>
            <CustomButton
              type='primary'
              htmlType='submit'
              size='large'
              fullWidth
              boldText
              loading={loading}
            >
              Giriş Yap
          </CustomButton>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Login
