import moment from 'moment'

class DateUtil {
  static now = () => moment()
  static today = () => moment().endOf('day')
  static tomorrow = () => moment().add(1, 'days')
  static yesterday = () => moment().subtract(1, 'days')
  static momentDate = date => moment(date)
  static currentYear = () => moment().year()
  static currentMonth = () => moment().month()
  static year = momentDate => momentDate.year()
  static localeLongDateFormat = format => moment.localeData().longDateFormat(format)
  static isToday = date => date.isSame(DateUtil.now(), 'day')
  static isTomorrow = date => date.isSame(DateUtil.tomorrow(), 'day')
}

export default DateUtil
