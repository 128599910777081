import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  contentWrapper: {
    background: '#fff',
    minHeight: 280,
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px'
  },
  pageTitle: {
    fontSize: '26px',
    fontWeight: 'bold',
    color: '#333',
    paddingLeft: '5px'
  }
})

export default useStyles