
import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { getItem } from 'utils/store'

export const getApolloClient = ({ onLogout }) => {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_BASE_URL
  })

  const authLink = setContext(async (_, { headers }) => {
    const token = await getItem('token')
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      }
    }
  })

  const errorLink = onError(errorResponse => {
    if (errorResponse?.graphQLErrors?.some(e => e.extensions?.code === 'UNAUTHENTICATED')) {
      onLogout()
    }
  })

  const cache = new InMemoryCache()

  return new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache,
  })
}
