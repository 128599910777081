import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { get, orderBy, isEmpty } from 'lodash'
import { Button, Card, Tag, Space } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { useMutation } from '@apollo/react-hooks'
import { DIVIDENDS } from 'api/dividend/query'
import { UPDATE_DIVIDEND } from 'api/dividend/mutation'
import { ContentLayout } from 'layouts'
import { DateUtil, ToastUtil } from 'utils'
import { Spinner, Error } from 'components'
import useStyles from './styles'

const BORSA_API_BASE_URL = 'https://api.collectapi.com/economy'

const UpdateYield = () => {
  const classes = useStyles()
  const [updatePending, setUpdatePending] = useState(false)
  const [updateDividend] = useMutation(UPDATE_DIVIDEND)

  const [current, setCurrent] = useState([])
  const [updated, setUpdated] = useState([])
  const [borsaDataMap, setBorsaDataMap] = useState({})
  const [borsaLoading, setBorsaLoading] = useState(false)

  const { loading, error, data } = useQuery(
    DIVIDENDS,
    { variables: { orderBy: 'id_DESC', year: DateUtil.currentYear() } }
  )

  const updateDividendRequest = async ({ id, yieldRate, index }) => {
    setUpdatePending(true)
    const response = await updateDividend(
      {
        variables: { id, data: { yieldRate } },
        refetchQueries: [
          { query: DIVIDENDS, variables: { orderBy: 'id_DESC' } }
        ]
      }
    )
    console.log(`${index + 1}. updating dividend id ${id}`, response)
    setUpdatePending(false)
    return response
  }

  useEffect(() => {
    if (data) {
      setCurrent(orderBy(data.dividends.filter(dividend => DateUtil.momentDate(dividend.date) > DateUtil.yesterday() || !dividend.date), ['date'], ['asc']))
    }
  }, [data])

  if (error) return <Error />

  const getBorsaLiveData = () => {
    setBorsaLoading(true)
    axios.get(`${BORSA_API_BASE_URL}/liveBorsa`, {
      headers: {
        authorization: process.env.REACT_APP_BORSA_API_KEY
      }
    })
      .then(function (response) {
        const data = response.data.result
        const map = {}

        data.forEach((stock) => {
          map[stock.name] = stock
        })
        ToastUtil.success('Borsa güncel verileri alındı')
        setBorsaDataMap(map)
        setBorsaLoading(false)
      })
      .catch(function (error) {
        ToastUtil.error(error)
        setBorsaLoading(false)
      })
  }

  const handleUpdateYieldRate = () => {
    getBorsaLiveData()
  }

  const getYieldRates = () => {
    const dividendsToUpdate = []

    current.forEach((dividend) => {
      const stockCode = dividend.stock.code
      const lastPriceStr = get(borsaDataMap[stockCode], 'pricestr') && get(borsaDataMap[stockCode], 'pricestr').replace('.', '')
      const lastPrice = lastPriceStr && parseFloat(lastPriceStr.replace(',', '.'))

      dividend = { ...dividend, yieldRate: (dividend.grossAmountPerShare / lastPrice * 100) ? Number((dividend.grossAmountPerShare / lastPrice * 100).toFixed(2)) : null }
      dividendsToUpdate.push(dividend)
    })
    setUpdated(dividendsToUpdate)
  }

  const handleUpdateRequest = () => {
    updated.forEach(({ id, yieldRate }, index) => setTimeout(() => {
      if (yieldRate) {
        updateDividendRequest({ id, yieldRate, index })
      }
    }, index * 1000))
  }

  return (
    <ContentLayout pageTitle='Verim Güncelle'>
      {loading ? <Spinner /> : (
        <div className={classes.root}>
          <div className={classes.form}>
            <Card title="Gelecek temettüler">
              {current.map((dividend, index) =>
                <div key={dividend.id}>
                  <strong>{index + 1}</strong> - <Tag>{dividend.stock.code}</Tag> - {dividend.date ? dividend.date : 'Belirsiz'} - <Tag>{dividend.yieldRate}</Tag> - {dividend.grossAmountPerShare ? <strong>{dividend.grossAmountPerShare} <em>(brüt)</em></strong> : <><span style={{ color: 'red '}}>X</span><em> (brüt)</em></>} - <span style={{ color: 'grey' }}>{dividend.amountPerShare} <em>(net)</em></span>
                </div>
              )}
            </Card>
          </div>
          <div className={classes.content}>
            <div style={{ marginBottom: 15 }}>
              <Space>
                <Button type="primary" onClick={handleUpdateYieldRate} loading={borsaLoading}>
                  Borsa Verileri Çek
              </Button>
                <Button type="primary" onClick={getYieldRates} disabled={isEmpty(borsaDataMap)}>
                  Güncel Verimleri Görüntüle
              </Button>
              </Space>
            </div>
            {!isEmpty(updated) && (
              <Card title="Güncellenecek temettüler">
                {updated.map((dividend, index) =>
                  <div key={dividend.id}><strong>{index + 1}</strong> - <Tag>{dividend.stock.code}</Tag> - {dividend.date ? dividend.date : 'Belirsiz'} - {dividend.yieldRate ? <Tag color="blue">{dividend.yieldRate}</Tag> : <span style={{ color: 'red '}}>X</span>} - {dividend.grossAmountPerShare ? <strong>{dividend.grossAmountPerShare} <em>(brüt)</em></strong> : <><span style={{ color: 'red '}}>X</span><em> (brüt)</em></>} - <span style={{ color: 'grey' }}>{dividend.amountPerShare} <em>(net)</em></span></div>
                )}
              </Card>
            )}
            {!isEmpty(updated) && (
              <Button size="large" style={{ backgroundColor: '#1486A2', color: 'white', width: '100%', marginTop: 15 }} onClick={handleUpdateRequest} loading={borsaLoading || updatePending}>
                Verimleri Güncelle
              </Button>
            )}
          </div>
        </div>
      )}
    </ContentLayout>
  )
}

export default UpdateYield
