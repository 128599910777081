import { toast } from 'react-toastify'

class ToastUtil {
  static init = () => (
    toast.configure({
      autoClose: 2000
    })
  )
  static success = message => (
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT
    })
  )
  static error = (
    message = 'Something went wrong.'
  ) => (
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      })
    )
}

export default ToastUtil