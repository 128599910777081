import React from 'react'
import moment from 'moment'
import { Tag } from 'antd'

export const getTableColumns = ({
  renderTableRowActions,
  getColumnSearchProps
}) => [ 
  {
    title: 'Kod',
    dataIndex: 'code',
    key: 'code',
    width: 120,
    ...getColumnSearchProps('code'),
  },
  {
    title: 'İsim',
    dataIndex: 'name',
    key: 'name',
    width: 400,
  },
  {
    title: 'Kullanım',
    dataIndex: 'active',
    key: 'active',
    width: 200,
    render: active => !active ? <Tag color='red'>Devre Dışı</Tag> : <Tag color='blue'>Aktif</Tag>
  },
  {
    title: 'Son Güncelleme',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: 200,
    render: updatedAt => moment(Number(updatedAt, 10)).format('L')
  },
  {
    title: 'Aksiyon',
    key: 'action',
    align: 'center',
    render: item => renderTableRowActions(item)
  }
]
