import React, { useState, useRef, useEffect } from 'react'
import Highlighter from 'react-highlight-words'
import { get } from 'lodash'
import { Input, Button, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { USERS } from 'api/user/query'
import { DataTable, Spinner, Error } from 'components'
import { getTableColumns } from './config'

const LIMIT = 10
const INITIAL_OFFSET = 0

const UsersTable = () => {
  const inputRef = useRef(null);

  const [limit] = useState(LIMIT)
  const [offset, setOffset] = useState(INITIAL_OFFSET)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const { loading, error, data } = useQuery(
    USERS,
    { variables: { orderBy: 'id_DESC', limit, offset, query: searchText || undefined } }
  )

  useEffect(() => {
    setOffset(INITIAL_OFFSET)
  }, [searchText])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputRef}
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => get(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => inputRef.current.select());
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : text
  })

  if (loading) return <Spinner />
  if (error) return <Error />

  const { users } = data

  return (
    <>
      <DataTable
        data={users}
        columns={getTableColumns({ getColumnSearchProps })}
        pagination={false}
      />
      <div style={{ padding: '15px 0', display: 'flex', justifyContent: 'center' }}>
        <Space>
          <Button onClick={() => setOffset(offset - limit)} disabled={offset - limit < 0}>Geri</Button>
          <Button onClick={() => setOffset(offset + limit)}>İleri</Button>
        </Space>
      </div>
    </>
  )
}

export default UsersTable