
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  flexRow: { display: 'flex' },
  rightSpaced: { marginRight: 10 },
  leftSpaced: { marginLeft: 10 },
  btnContainer: { display: 'flex', justifyContent: 'flex-end' },
  cancelBtn: { marginRight: 5 }
})

export default useStyles