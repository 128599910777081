import { gql } from 'apollo-boost'

export const USERS = gql`
  query Users($limit: Int, $offset: Int, $orderBy: String, $query: String, $active: Boolean) {
    users(limit: $limit, offset: $offset, orderBy: $orderBy, query: $query, active: $active) {
      id
      name
      username
      email
      active
      createdAt
      updatedAt
    }
  }
`