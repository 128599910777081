import { trim } from 'lodash'

export const getFormattedData = ({ name, code, active }) => ({
  name,
  code: code ? code.toUpperCase() : null,
  active
})

export const validate = ({ name, code }) => {
  if (!trim(name)) return false
  if (!trim(code)) return false

  return true
}