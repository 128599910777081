import { gql } from 'apollo-boost'

export const CREATE_STOCK = gql`
  mutation CreateStock($data: CreateStockInput!) {
    createStock(data: $data) {
      id
    }
  }
`
export const DELETE_STOCK = gql`
  mutation DeleteStock($id: Int!) {
    deleteStock(id: $id)
  }
`
export const UPDATE_STOCK = gql`
  mutation UpdateStock(
    $id: Int!
    $data: UpdateStockInput!
  ) {
    updateStock(id: $id data: $data) {
      id
    }
  }
`