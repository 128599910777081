import React from 'react'
import { ContentLayout } from 'layouts'
import { UsersTable } from './components'

const Users = () => (
  <ContentLayout pageTitle='Kullanıcılar'>
    <UsersTable />
  </ContentLayout >
)

export default Users
