import React, { useState } from 'react'
import { Button, Form, Input, Switch } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { DIVIDENDS } from 'api/dividend/query'
import { CREATE_DIVIDEND } from 'api/dividend/mutation'
import { CustomButton, StockSelect, StatusSelect, DatePicker } from 'components'
import { ToastUtil } from 'utils'
import { DIVIDEND_STATUS } from 'constants/common'
import { getFormattedData, validate } from '../utils'
import useStyles from './styles'

const LIMIT = 10
const INITIAL_OFFSET = 0

const AddDividendForm = ({ onCancel }) => {
  const classes = useStyles()
  const [dividendToCreate, setDividendToCreate] = useState({
    status: Object.keys(DIVIDEND_STATUS)[0],
    active: true
  })
  const [createPending, setCreatePending] = useState(false)
  const [createDividend] = useMutation(CREATE_DIVIDEND)

  const createDividendRequest = async data => {
    setCreatePending(true)
    const response = await createDividend(
      {
        variables: { data },
        refetchQueries: [
          { query: DIVIDENDS, variables: { orderBy: 'id_DESC', limit: LIMIT, offset: INITIAL_OFFSET } }
        ]
      }
    )
    setCreatePending(false)
    return response
  }

  const handleSubmit = async () => {
    const data = getFormattedData(dividendToCreate)

    if (!validate(data)) {
      ToastUtil.error('Geçersiz veri')
      return
    }

    try {
      await createDividendRequest(data)
      ToastUtil.success('Başarıyla eklendi')

      onCancel()
    } catch (error) {
      setCreatePending(false)
      ToastUtil.error(error.message)
    }
  }

  const { amountPerShare, yieldRate } = dividendToCreate

  return (
    <Form
      layout='vertical'
      onFinish={handleSubmit}
    >
      <Form.Item label='Hisse'>
        <StockSelect onChange={value => setDividendToCreate({ ...dividendToCreate, stockId: value })} />
      </Form.Item>
      <Form.Item label='Miktar'>
        <div className={classes.flexRow}>
          <Input
            className={classes.rightSpaced}
            addonBefore="Brüt ₺"
            placeholder='Brüt Miktar'
            value={undefined}
            onChange={event => setDividendToCreate({ ...dividendToCreate, grossAmountPerShare: event.target.value })}
          />
          <Input
            addonBefore="Net ₺"
            placeholder='Net Miktar'
            value={amountPerShare}
            onChange={event => setDividendToCreate({ ...dividendToCreate, amountPerShare: event.target.value })}
          />
        </div>
      </Form.Item>
      <Form.Item label='Verim'>
        <div className={classes.flexRow}>
          <Input
            placeholder='Verim'
            addonBefore="Verim %"
            value={yieldRate}
            onChange={event => setDividendToCreate({ ...dividendToCreate, yieldRate: event.target.value })}
          />
        </div>
      </Form.Item>
      <Form.Item label='Tarih - Ödeme Tarihi'>
        <div className={classes.flexRow}>
          <DatePicker
            className={classes.rightSpaced}
            onChange={(date, dateString) => setDividendToCreate({ ...dividendToCreate, date: dateString })}
          />
          <DatePicker
            className={classes.datePicker}
            onChange={(date, dateString) => setDividendToCreate({ ...dividendToCreate, paymentDate: dateString })}
          />
        </div>
      </Form.Item>
      <Form.Item label='Durum - Kullanım '>
        <div className={classes.flexRow}>
          <StatusSelect
            className={classes.rightSpaced}
            onChange={value => setDividendToCreate({ ...dividendToCreate, status: value })}
          />
          <div className={classes.switch}>
            <Switch
              checkedChildren='Aktif'
              unCheckedChildren='Devre Dışı'
              defaultChecked
              onChange={checked => setDividendToCreate({ ...dividendToCreate, active: checked })}
            />
          </div>
        </div>
      </Form.Item>
      <div className={classes.btnContainer}>
        <Button
          className={classes.cancelBtn}
          onClick={onCancel}
        >
          İptal
        </Button>
        <CustomButton
          type='primary'
          htmlType='submit'
          loading={createPending}
        >
          Oluştur
        </CustomButton>
      </div>
    </Form>
  )
}

export default AddDividendForm