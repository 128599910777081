import { ToastUtil } from 'utils'

export const getResizedImageUrl = (unsplashUrl, width) => (
  `${unsplashUrl}&auto=format&fit=crop&w=${width}&q=80`
)

export const copyToClipboard = async (text) => {
  try {
    const el = document.createElement('textarea')
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    ToastUtil.success('Copied to clipboard!')
  } catch (err) {
    ToastUtil.error('Failed to copy')
  }
}
