import { gql } from 'apollo-boost'

export const ADMIN_SIGN_IN = gql`
  mutation AdminSignIn($email: String!, $password: String!) {
    adminSignIn(email: $email, password: $password) {
      admin {
        id
        name
        email
      }
      token
    }
  }
`
export const ADMIN_SIGN_UP = gql`
  mutation AdminSignUp($name: String, $email: String!, $password: String) {
    adminSignUp(name: $name, email: $email, password: $password) {
      admin {
        id
        name
        email
      }
      token
    }
  }
`