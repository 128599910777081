import React, { useState } from 'react'
import { Button, Form, Input, Switch } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { STOCKS } from 'api/stock/query'
import { CREATE_STOCK } from 'api/stock/mutation'
import { CustomButton } from 'components'
import { ToastUtil } from 'utils'
import { getFormattedData, validate } from '../utils'
import useStyles from './styles'

const LIMIT = 10
const INITIAL_OFFSET = 0

const AddStockForm = ({ onCancel }) => {
  const classes = useStyles()
  const [stockToCreate, setStockToCreate] = useState({ active: true })
  const [createPending, setCreatePending] = useState(false)
  const [createStock] = useMutation(CREATE_STOCK)

  const createStockRequest = async data => {
    setCreatePending(true)
    const response = await createStock(
      {
        variables: { data },
        refetchQueries: [
          { query: STOCKS, variables: { orderBy: 'id_DESC', limit: LIMIT, offset: INITIAL_OFFSET } },
          { query: STOCKS, variables: { orderBy: 'name_ASC' } }
        ]
      }
    )
    setCreatePending(false)
    return response
  }

  const handleSubmit = async () => {
    const data = getFormattedData(stockToCreate)

    if (!validate(data)) {
      ToastUtil.error('Geçersiz veri')
      return
    }

    try {
      await createStockRequest(data)
      ToastUtil.success('Başarıyla eklendi')

      onCancel()
    } catch (error) {
      setCreatePending(false)
      ToastUtil.error(error.message)
    }
  }
  const { name, code } = stockToCreate

  return (
    <Form
      layout='vertical'
      onFinish={handleSubmit}
    >
      <Form.Item label='Kod'>
        <div className={classes.flexRow}>
          <Input
            placeholder='Kod'
            value={code ? code.toUpperCase() : null}
            onChange={event => setStockToCreate({ ...stockToCreate, code: event.target.value })}
          />
        </div>
      </Form.Item>
      <Form.Item label='İsim'>
        <Input
          placeholder='İsim'
          value={name}
          onChange={event => setStockToCreate({ ...stockToCreate, name: event.target.value })}
        />
      </Form.Item>
      <Form.Item label='Kullanım'>
        <Switch
          checkedChildren='Aktif'
          unCheckedChildren='Devre Dışı'
          defaultChecked
          onChange={checked => setStockToCreate({ ...stockToCreate, active: checked })}
        />
      </Form.Item>
      <div className={classes.btnContainer}>
        <Button
          className={classes.cancelBtn}
          onClick={onCancel}
        >
          İptal
        </Button>
        <CustomButton
          type='primary'
          htmlType='submit'
          loading={createPending}
        >
          Oluştur
        </CustomButton>
      </div>
    </Form>
  )
}

export default AddStockForm
