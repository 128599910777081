import React from 'react'
import { ContentLayout } from 'layouts'
import useStyles from './styles'

const Tools = () => {
  const classes = useStyles()

  return (
    <ContentLayout pageTitle='Araçlar'>
      <div className={classes.root}>
        Tools dashboard
      </div>
    </ContentLayout>
  )
}

export default Tools
