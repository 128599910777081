import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { STATS } from 'api/stats/query'
import { Spinner, Error } from 'components'
import {
  LineChartOutlined,
  PieChartOutlined,
  CalendarOutlined,
  UserOutlined
} from '@ant-design/icons'
import { NumberCard } from 'components'

const NumberCardsRow = () => {
  const { loading, error, data } = useQuery(STATS)

  if (loading) return <Spinner />
  if (error) return <Error />

  const { stats } = data
  const { stockCount, dividendCount, daysPassed, userCount } = stats

  return (
    <>
      <Row gutter={24}>
        <Col lg={8} md={12}>
          <Link to='/users'>
            <NumberCard
              renderIcon={() => <UserOutlined />}
              isBordered={false}
              title='Kullanıcılar'
              number={userCount}
            />
          </Link>
        </Col>
        <Col lg={8} md={12}>
          <Link to='/stocks'>
            <NumberCard
              renderIcon={() => <LineChartOutlined />}
              isBordered={false}
              title='Hisseler'
              number={stockCount}
            />
          </Link>
        </Col>
        <Col lg={8} md={12}>
          <Link to='/dividends'>
            <NumberCard
              renderIcon={() => <PieChartOutlined />}
              isBordered={false}
              title='Temettüler'
              number={dividendCount}
            />
          </Link>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col lg={8} md={12}>
          <NumberCard
            renderIcon={() => <CalendarOutlined />}
            isBordered={false}
            title='Geçen Gün'
            number={daysPassed}
          />
        </Col>
      </Row>
    </>
  )
}

export default NumberCardsRow